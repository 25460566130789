























































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "@/components/main-header/main-header.vue";
import Empty from "@/components/empty.vue";
import echarts from "echarts";
import { GetYianDetail } from "@/request/yian";
@Component({
  components: {
    MainHeader,
    Empty,
  },
})
export default class Name extends Vue {
  private data: any = {};
  private id: any = "";
  private tupuData: any = {
    categories: [],
    links: [],
    nodes: [],
  };
  private ifShowTupu: any = false;
  private keys: any = [
    {
      name: "编号",
      label: "编码",
    },
    {
      name: "实体名称",
      label: "实体名称",
    },
    {
      name: "症状体征",
      label: "症状体征",
    },
    {
      name: "病因",
      label: "病因",
    },
    {
      name: "病机",
      label: "病机",
    },
    {
      name: "中医疾病",
      label: "中医疾病",
    },
    {
      name: "中医证型",
      label: "中医证型",
    },
    {
      name: "西医疾病",
      label: "西医疾病",
    },
    {
      name: "中医治法",
      label: "中医治法",
    },
    {
      name: "中医治则",
      label: "中医治则",
    },
    {
      name: "中医方剂",
      label: "中医方剂",
    },
    {
      name: "中药饮片",
      label: "中药饮片",
    },
  ];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private get jibing() {
    return this.$store.state.jibing;
  }
  private get type() {
    return this.$store.state.zhuanti;
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        medcase_id: this.id,
      },
    };
    GetYianDetail(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res;
        this.getTupuData();
      })
      .catch(() => {
        loading.close();
      });
    // this.data = {
    //   id: "TS09887",
    //   医家: "何立人",
    //   年代: "清代",
    //   源文献: "《何李人医案集》",
    //   章节: "第3章心系病医案-第4节冠心病",
    //   原文: "肖,女，24岁。哮喘10 年，频发3月。病人小时即患哮喘，多在每年春季发作。有异痒、流涕等前驱症而后胸部发紧，呼气费力，并发出哮鸣音。通常用状，氨茶碱及激素来缓解症状。今年春天开始至今已 3月，发作频率比往年增加，且持续时间亦延长。特为减少发作频率而来求中药治疗。病人面色淡白，自觉乏力气短，平素易汗，极易感舌淡苔白，脉浮缓。否认家族遗传。诊断:支气管哮喘处方:黄80g，白术20g，防风20g，白蚕15g，蜕15g地龙20g，全竭粉 6g(吞)，甘草 10g连用10剂，病情稳定，随访半年未复发病人患病日久，必有痰瘀伏于肺络，同时肺卫虚极，防御力差故邪风可轻易入里，引动痰瘀，发为哮喘。可见，祛风化痰瘀重要，扶正强卫表亦重要。方中黄英、白术等乃玉屏风散可强卫固表，提升防御机能。此法是降低发作频率的有效手段之一。白僵蚕、蝉蜕、地龙、全竭均属虫类药。其祛风化痰、活血通络之功殊为稳捷，且善剔除潜伏之邪。由于伏邪是哮喘反复发作的内在因素，故此法亦是降低发作频率的有效手段。该方扶正剂量颇大，祛邪虫药居多，看似普通无奇，实则效力非凡。",
    //   诊次: [
    //     {
    //       诊次: "初诊",
    //       病名: "精神不适伴记忆力下降1年。",
    //       病因: "患者近1年来时感精神紧张、焦虑，有时急躁，有时语言混乱，不认识人，伴有记忆力下降,理解力下降。刻下症：精神异常，时焦虑，时急躁，时语言错乱不知人，难理解医生所言，血压不平稳，大便尚可，小便频，夜2－3次，纳可，眠可。",
    //       病机: "病机1",
    //       症状: "症状1",
    //       辨证: "",
    //       治则治法: "舌淡红，苔薄黄腻",
    //       处方: "面色萎黄",
    //       "药物/非药物": "脉细弦",
    //       加减变治: "阴虚火旺证",
    //       随法: "1、健忘；2、痴呆",
    //       疗效: "1、老年痴呆；2、原发性高血压；3、高血脂症",
    //       预后: "滋阴清热，宁心安神",
    //       机理: "小青龙汤",
    //       预防: "处方:黄80g，白术20g，防风20g，白僵蚕15g，蝉蜕15g地龙 20g，全蝎粉 6g(吞)，甘草 10g。连用10剂，病情稳定，随访半年未复发。",
    //       康复: "平时可练八段锦多运动。",
    //     },
    //     {
    //       诊次: "二诊",
    //       病名: "精神不适伴记忆力下降1年。",
    //       病因: "患者近1年来时感精神紧张、焦虑，有时急躁，有时语言混乱，不认识人，伴有记忆力下降,理解力下降。刻下症：精神异常，时焦虑，时急躁，时语言错乱不知人，难理解医生所言，血压不平稳，大便尚可，小便频，夜2－3次，纳可，眠可。",
    //       病机: "病机1",
    //       症状: "症状1",
    //       辨证: "辨证1",
    //       治则治法: "舌淡红，苔薄黄腻",
    //       处方: "面色萎黄",
    //       "药物/非药物": "脉细弦",
    //       加减变治: "阴虚火旺证",
    //       随法: "1、健忘；2、痴呆",
    //       疗效: "1、老年痴呆；2、原发性高血压；3、高血脂症",
    //       预后: "滋阴清热，宁心安神",
    //       机理: "小青龙汤",
    //       预防: "处方:黄80g，白术20g，防风20g，白僵蚕15g，蝉蜕15g地龙 20g，全蝎粉 6g(吞)，甘草 10g。连用10剂，病情稳定，随访半年未复发。",
    //       康复: "平时可练八段锦多运动。",
    //     },
    //   ],
    // };
  }
  private getTupuData() {
    // let data = {
    //   categories: [
    //     {
    //       name: "中医疾病",
    //     },
    //     {
    //       name: "脏腑",
    //     },
    //   ],
    //   links: [
    //     {
    //       name: "是...下位",
    //       source: "63f80c49abe8e4005587cf74",
    //       target: "63f80c49abe8e4005587cf72",
    //     },
    //     {
    //       name: "是...下位",
    //       source: "63f80c4babe8e4005587cf9c",
    //       target: "63f80c49abe8e4005587cf72",
    //     },
    //     {
    //       name: "是...下位",
    //       source: "63fdb145abe8e4002527f4bd",
    //       target: "63f80c49abe8e4005587cf72",
    //     },
    //     {
    //       name: "是...下位",
    //       source: "63fdc990abe8e4000beafce4",
    //       target: "63f80c49abe8e4005587cf72",
    //     },
    //     {
    //       name: "包围",
    //       source: "63f8105babe8e4005588240b",
    //       target: "63f80c49abe8e4005587cf72",
    //     },
    //   ],
    //   nodes: [
    //     {
    //       category: "中医疾病",
    //       id: "63f80c49abe8e4005587cf74",
    //       name: "传染病",
    //       type: "实体",
    //     },
    //     {
    //       category: "中医疾病",
    //       id: "63f80c49abe8e4005587cf72",
    //       name: "传染病与寄生虫病",
    //       type: "目标",
    //     },
    //     {
    //       category: "中医疾病",
    //       id: "63f80c4babe8e4005587cf9c",
    //       name: "寄生虫病",
    //       type: "实体",
    //     },
    //     {
    //       category: "中医疾病",
    //       id: "63fdb145abe8e4002527f4bd",
    //       name: "测试2",
    //       type: "实体",
    //     },
    //     {
    //       category: "中医疾病",
    //       id: "63fdc990abe8e4000beafce4",
    //       name: "测测",
    //       type: "实体",
    //     },
    //     {
    //       category: "脏腑",
    //       id: "63f8105babe8e4005588240b",
    //       name: "六腑",
    //       type: "实体",
    //     },
    //   ],
    // };
    let data = this.data;
    data.nodes.forEach((ele: any, index: any) => {
      if (ele.type === "目标") {
        ele.symbol = "roundRect";
        ele.symbolSize = 25;
      } else if (ele.type === "实体") {
        ele.symbolSize = 20;
      } else {
        ele.symbolSize = 1;
      }
    });
    this.tupuData = data;
    this.drawTupu1();
  }
  private drawTupu1() {
    const Chart: any = echarts.init(this.$refs.tupu as HTMLCanvasElement);
    const options: any = {
      color: this.colors,
      title: {
        text: "",
        subtext: "",
        top: "bottom",
        left: "right",
      },
      tooltip: {},
      legend: [
        {
          // selectedMode: 'single',
          data: this.tupuData.categories.map(function (a: any) {
            return a.name;
          }),
        },
      ],
      // animationDuration: 1500,
      // animationEasingUpdate: "quinticInOut",
      series: [
        {
          name: "",
          type: "graph",
          layout: "force",
          data: this.tupuData.nodes,
          links: this.tupuData.links,
          categories: this.tupuData.categories,
          zoom: 3,
          roam: true,
          label: {
            show: true,
            position: "inside",
            formatter: "{b}",
          },
          edgeLabel: {
            show: true,
            formatter: (res: any) => {
              return res.data.name;
            },
          },
          edgeSymbol: ["", "arrow"],
          lineStyle: {
            color: "source",
            width: 1,
            curveness: 0.1,
          },
          emphasis: {
            show: false,
            focus: "adjacency",
            lineStyle: {
              width: 6,
            },
          },
          force: {
            layoutAnimation: false, //关闭初始化转动动画
          },
        },
      ],
    };
    Chart.setOption(options, true);
    // 点击事件
    // Chart.off("click");
    // Chart.on("click", (p: any) => {
    //   const data: any = {
    //     entity_id: p.id,
    //     entity_name: p["name"],
    //     entity_type: p["category"],
    //   };
    // });
  }
  private drawTupu2() {
    const Chart: any = echarts.init(this.$refs.tupu2 as HTMLCanvasElement);
    const options: any = {
      color: this.colors,
      title: {
        text: "",
        subtext: "",
        top: "bottom",
        left: "right",
      },
      tooltip: {},
      legend: [
        {
          // selectedMode: 'single',
          data: this.tupuData.categories.map(function (a: any) {
            return a.name;
          }),
        },
      ],
      animationDuration: 1500,
      animationEasingUpdate: "quinticInOut",
      series: [
        {
          name: "",
          type: "graph",
          layout: "force",
          data: this.tupuData.nodes,
          links: this.tupuData.links,
          categories: this.tupuData.categories,
          zoom: 3,
          roam: true,
          label: {
            show: true,
            position: "inside",
            formatter: "{b}",
          },
          edgeLabel: {
            show: true,
            formatter: (res: any) => {
              return res.data.name;
            },
          },
          edgeSymbol: ["", "arrow"],
          lineStyle: {
            color: "source",
            width: 1,
            curveness: 0.1,
          },
          emphasis: {
            show: false,
            focus: "adjacency",
            lineStyle: {
              width: 6,
            },
          },
          force: {
            layoutAnimation: false, //关闭初始化转动动画
          },
        },
      ],
    };
    Chart.setOption(options, true);
    // 点击事件
    // Chart.off("click");
    // Chart.on("click", (p: any) => {
    //   const data: any = {
    //     entity_id: p.id,
    //     entity_name: p["name"],
    //     entity_type: p["category"],
    //   };
    // });
  }
  private openBigTupu() {
    this.ifShowTupu = true;
    this.drawTupu2();
  }
  private exit() {
    this.ifShowTupu = false;
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  }
}
